import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom"
import InfoBox from "../../components/infoBox/InfoBox";
import BottomGrid2FLOZoneSetup from "../../components/BottomGrid2FLO";
import alertIcon from "../../assets/images/alert.svg"
import Mod1 from "../../components/mod1/Mod1";
import AuthService from "../../Auth/auth";
import HeaderSecondary from "../../components/headerSecondary/HeaderSecondary";
import Loader from "../../components/Loader/Loader";
import { useMediaQuery } from 'react-responsive'
import BottomGridEditFLOZoneSetup from '../../components/BottomGridEditFLOZoneSetup';
import { SpotWSS } from 'wf-utils/spot/spot-wss';
import { TempGrid } from '../live-grid/live-grid-FLO/LiveGridFLO';
import Mod2 from '../../components/mod1/Mod2';
import infoIcon from "../../assets/images/infoIcon.svg";
import config from '../../config';
// import moment from 'moment-timezone';


const subscribeSpot = async (onData, assetId, templateId) => {
    const subscriptions = [{ assetId: assetId, templateId: templateId }];
    const key = localStorage.getItem("spaceio-key");
    const spotWs = new SpotWSS({
        subscriptions,
        wsUrl: config.WEB_SOCKET_URL,
        token: key,
        onData,
    });

    return () => {
        spotWs.disposeConnection();
    };
};

function FLOSetUp() {
    const columns = 32;
    const rows = 24;
    const location = useLocation();
    // console.log("LOC", location);
    const navigate = useNavigate();

    let title = 'Set-Up Zone';
    let floorId = location.state.selectedFLOSpaceData.deviceApi?.assignedSpace?.locationId;
    let floorName = location.state.selectedFLOSpaceData.deviceApi?.assignedSpace?.locationName;
    let room = location.state.selectedFLOSpaceData.deviceApi.displayName;
    let assetId = location.state.selectedFLOSpaceData.assetId;
    let agentId = location.state.selectedFLOSpaceData.deviceApi?.assignedSpace?.device.hardware.agentId;
    let [floConfigData, setfloConfigData] = useState({})

    const [params, setParams] = useState({
        enableFrame: true,
        width: 700,
        showKeys: false,
        showBorder: false,
        verticalLines: [],
        horizontalLines: [],
    })

    const [zoneSetupClick, setZoneSetupClick] = useState(false);
    const [zoneSetupConfirmBtn, setZoneSetupConfirmBtn] = useState(false);
    const [EditClick, setEditClick] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [modalDetails, setmodalDetails] = useState({
        primaryText: "Please ensure the field of view is vacant.",
        secondaryText: "Click below to calibrate FLO. Please ensure field of view is vacant during calibration.",
        icon: alertIcon,
        inputBox: false,
        confirmButtonText: "Confirm Vacant"
    });
    const [activity, setActivity] = useState(null);
    const [loading, setloading] = useState(false);
    const [resetCall, setResetCall] = useState(0);
    const info = "Frame will automatically turn off after 15 minutes of inactivity.";
    let [reset, setReset] = useState(true);
    let [zoneConfig, setZoneConfig] = useState({});
    const [timer, setTimer] = useState(60);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    useEffect(() => {
        async function getpgInfo() {
            let spotDataFLO = await AuthService.getConfigZone([assetId])
            // console.log(spotDataFLO);
            if (spotDataFLO[0].floMetaDataConfigStatus === 1) {
                // console.log("we will not do anything ");
            }
            else {
                spotDataFLO[0].floMetaDataConfigStatus = 1
                let dataToWrite = [{
                    "assetId": assetId,
                    "data": { ...spotDataFLO[0] },

                }]
                await AuthService.updateFLOConfig(dataToWrite);
            }
        }
        getpgInfo();
    }, [])

    useEffect(function () {
        async function fetchData() {
            let key = window.localStorage.getItem('spaceio-key');
            if (key !== null) {
                setloading(true)
                const getData = await AuthService.getConfigZone([assetId]);
                setfloConfigData(getData)
                setZoneConfig(getData[0].zone);
                setloading(false);
            }
        }
        fetchData();
    }, [resetCall]);

    useEffect(() => {
        if (assetId && params.enableFrame === true) {

            const unsubscribe = subscribeSpot(handleSpotData, assetId, 11);
            return () => {
                // unsubscribe();
            };
        }
    }, [params]);

    useEffect(() => {
        if (params != null && params.enableFrame != null && agentId) {
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            let raw = JSON.stringify({
                "frame_debug": params.enableFrame
            });
            let url = `https://agent.electricimp.com/${agentId}/frame_debug`;
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(url, requestOptions)
                .then(response => response.text())
                .catch(error => console.log('error', error));
        }
    }, [params && params.enableFrame]);

    async function setupDone() {
        let key = localStorage.getItem('spaceio-key');
        let utcTimestamp = new Date().getTime();
        // console.log('floConfigData',floConfigData[0])

        floConfigData[0].floMetaDataConfigStatus = 2;
        // floConfigData[0].zone = floConfigData[0].zone
        
        let dataToWrite = [{
            "assetId": assetId,
            "data": { ...floConfigData[0] },

        }]
        console.log('dataToWrite',dataToWrite)
        await AuthService.updateFLOConfig(dataToWrite)
    }
    async function coldReboot() {
        await AuthService.coldReboot(agentId);
    }

    function resetAndDone() {
        setResetCall(resetCall + 1);
        setReset(!reset)
    }

    function resetCallback() {
        setResetCall(resetCall + 1);
        reSetZone()
    }

    const handleEditClick = () => {
        setEditClick(true);
    };

    const handleSpotData = ({ data }) => {
        setActivity(data);
    };

    let getrows, getcolumns;
    const leftShift = (zone) => {
        let newArray = [];
        getcolumns = 32;
        getrows = 24;
        // Create the 2D array with the desired number of rows and columns
        newArray = Array.from({ length: getrows }, () => Array.from({ length: getcolumns }));
        // Iterate over the original array and fill the 2D array with its values
        for (let i = 0; i < zone.length; i++) {
            const row = Math.floor(i / getcolumns);
            const col = i % getcolumns;
            newArray[row][col] = zone[i];
        }
        // Check if the original array can be evenly divided into the desired rows and columns
        if (zone.length !== getcolumns * getrows) {
            console.error("Original array size does not match desired rows and columns");
        } else {

            if (floConfigData[0].orientationAngle === 0) {
                // Iterate over each row in the 2D array and shift the 11s and 12s to the left
                for (let i = 0; i < newArray.length; i++) {
                    const row = newArray[i];
                    if (row[row.length - 1] !== 11 && row[row.length - 1] !== 12) {
                        for (let i = row.length - 1; i > 0; i--) {
                            row[i] = row[i - 1];
                        }
                        row[0] = 0;
                    } else {
                        return;
                    }
                    newArray[i] = [...row];
                }
            } else if (floConfigData[0].orientationAngle === 180) {
                // Iterate over each row in the 2D array and shift the 11s and 12s to the left
                for (let i = 0; i < newArray.length; i++) {
                    const row = newArray[i];
                    if (row[0] !== 11 && row[0] !== 12) {
                        for (let j = 0; j < row.length - 1; j++) {
                            row[j] = row[j + 1];
                        }
                        row[row.length - 1] = 0;
                    } else {
                        return;
                    }
                    newArray[i] = [...row];
                }
            } else if (floConfigData[0].orientationAngle === 90) {
                // Iterate over each row in the 2D array and shift the 11s and 12s to the left
                for (let col = 0; col < newArray[0].length; col++) {
                    let hasEmptyCell = false;
                    for (let row = 1; row < newArray.length; row++) {
                        if (newArray[row][col] !== 0 && newArray[row - 1][col] === 0) {
                            [newArray[row][col], newArray[row - 1][col]] = [
                                newArray[row - 1][col],
                                newArray[row][col],
                            ];
                            hasEmptyCell = true;
                        }
                    }
                    // if (!hasEmptyCell) {
                    // }
                }
            } else if (floConfigData[0].orientationAngle === 270) {
                // Iterate over each row in the 2D array and shift the 11s and 12s to the left
                for (let col = 0; col < newArray[0].length; col++) {
                    let hasEmptyCell = false;
                    for (let row = newArray.length - 2; row >= 0; row--) {
                        if (newArray[row][col] !== 0 && newArray[row + 1][col] === 0) {
                            [newArray[row][col], newArray[row + 1][col]] = [
                                newArray[row + 1][col],
                                newArray[row][col],
                            ];
                            hasEmptyCell = true;
                        }
                    }
                }
            }
            let NewZoneConfig = newArray.flat();
            setZoneConfig(NewZoneConfig);
        }
    };

    const RightShift = (zone) => {
        let newArray = [];
        getcolumns = 32;
        getrows = 24;
        // Create the 2D array with the desired number of rows and columns
        newArray = Array.from({ length: getrows }, () => Array.from({ length: getcolumns }));
        // Iterate over the original array and fill the 2D array with its values
        for (let i = 0; i < zone.length; i++) {
            const row = Math.floor(i / getcolumns);
            const col = i % getcolumns;
            newArray[row][col] = zone[i];
        }
        // Check if the original array can be evenly divided into the desired rows and columns
        if (zone.length !== getcolumns * getrows) {
            // console.error("Original array size does not match desired rows and columns");
        } else {
            if (floConfigData[0].orientationAngle === 0) {
                // Iterate over each row in the 2D array and shift the 11s and 12s to the left
                for (let i = 0; i < newArray.length; i++) {
                    const row = newArray[i];
                    if (row[0] !== 11 && row[0] !== 12) {
                        for (let j = 0; j < row.length - 1; j++) {
                            row[j] = row[j + 1];
                        }
                        row[row.length - 1] = 0;
                    } else {
                        return;
                    }
                    newArray[i] = [...row];
                }
            } else if (floConfigData[0].orientationAngle === 180) {
                // Iterate over each row in the 2D array and shift the 11s and 12s to the left
                for (let i = 0; i < newArray.length; i++) {
                    const row = newArray[i];
                    if (row[row.length - 1] !== 11 && row[row.length - 1] !== 12) {
                        for (let i = row.length - 1; i > 0; i--) {
                            row[i] = row[i - 1];
                        }
                        row[0] = 0;
                    } else {
                        return;
                    }
                    newArray[i] = [...row];
                }
            } else if (floConfigData[0].orientationAngle === 90) {
                // Iterate over each row in the 2D array and shift the 11s and 12s to the left
                for (let col = 0; col < newArray[0].length; col++) {
                    let hasEmptyCell = false;
                    for (let row = newArray.length - 2; row >= 0; row--) {
                        if (newArray[row][col] !== 0 && newArray[row + 1][col] === 0) {
                            [newArray[row][col], newArray[row + 1][col]] = [
                                newArray[row + 1][col],
                                newArray[row][col],
                            ];
                            hasEmptyCell = true;
                        }
                    }
                }
            } else if (floConfigData[0].orientationAngle === 270) {
                // Iterate over each row in the 2D array and shift the 11s and 12s to the left
                for (let col = 0; col < newArray[0].length; col++) {
                    let hasEmptyCell = false;
                    for (let row = 1; row < newArray.length; row++) {
                        if (newArray[row][col] !== 0 && newArray[row - 1][col] === 0) {
                            [newArray[row][col], newArray[row - 1][col]] = [
                                newArray[row - 1][col],
                                newArray[row][col],
                            ];
                            hasEmptyCell = true;
                        }
                    }
                }
            }
            let NewZoneConfig = newArray.concat.apply([], newArray);
            setZoneConfig(NewZoneConfig)
        }
    };

    const reSetZone = async (zone) => {
        let ResetZones = await AuthService.getConfigZone([assetId]);
        setZoneConfig(ResetZones[0].zone);
    }

    const saveUpdatedZone = async (zone) => {
        floConfigData.zone = zone;
    }

    const CalibartionConfrimationByModal = () => {
        setloading(true)
        if (agentId != null) {
            let url = `https://agent.electricimp.com/${agentId}/flo/Calibarte`;
            const options = { method: 'GET' };
            fetch(url, options)
                .then(response => {
                    if (response.status === 200) {
                        setloading(false)
                    }
                })
                .catch(err => console.error(err));
        }
    }

    const handleCalibrateClick = () => {
        setShowModal(true);
    };

    const zoenShiftLeft = () => {
        // console.log('zoneConfig in left',zoneConfig)
        leftShift(zoneConfig)
    }

    const zoneShiftRight = () => {
        // console.log('zoneConfig in right',zoneConfig)
        RightShift(zoneConfig)
    }

    function card(backgroundColor, header, value) {
        if (value === null) {
            return 0;
        }

        return <span style={{
            backgroundColor,
            margin: '0px 10px',
            // marginBottom: '5px',
            padding: '10px 20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            flex: 1,
            color: 'white',
            borderStyle: 'solid',
            borderRadius: "5px",
        }}>
            <span style={{
                fontSize: '70%',
                textAlign: 'center',
            }}>
                {value}
            </span>
            <span style={{
                fontSize: '70%',
                textAlign: 'center'
            }}>
                {header}
            </span>
        </span>;
    }

    const entryLabel = () => {
        if (params.enableFrame) {
            return (
                <div
                    style={{
                        color: "white",
                        backgroundColor: "#66DE93",
                        width: "35px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                    }}
                >
                    <p
                        style={{
                            fontSize: "12px",
                            textAlign: "center",
                            transform: "rotate(90deg)",
                            whiteSpace: "nowrap",
                        }}
                    >
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                        &nbsp;&nbsp;&nbsp;<b>INSIDE / ENTRY SIDE</b>&nbsp;&nbsp;&nbsp;
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                    </p>
                </div>
            );
        } else {
            return null;
        }
    };

    const exitLable = () => {
        if (params.enableFrame) {
            return (
                <div
                    style={{
                        color: "white",
                        backgroundColor: "#FF616D",
                        width: "35px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                    }}
                >
                    <p
                        style={{
                            fontSize: "12px",
                            textAlign: "center",
                            transform: "rotate(270deg)",
                            whiteSpace: "nowrap",
                        }}
                    >
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                        &nbsp;&nbsp;&nbsp;<b>EXIT SIDE / OUTSIDE</b>&nbsp;&nbsp;&nbsp;
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                        <i className="fa fa-arrow-down"></i>
                    </p>
                </div>
            );
        } else {
            return null;
        }
    };

    const renderFrame = (rows, columns, frame) => {
      
        // if (!frame || !frame.length || !params.enableFrame || activity == null) {
        //     // return <div>{card('black', '', 'Frame is OFF')} </div>;
        //     return <div>{dummyCard('#202153', 'Frame is OFF')} </div>;
        // }

        return <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TempGrid
                width={params.width}
                rows={rows}
                columns={columns}
                hideKey={!params.showKeys}
                hideBorder={!params.showBorder}
                frameData={frame}
                verticalLines={params.verticalLines}
                horizontalLines={params.horizontalLines}
                orientation={floConfigData[0].orientationAngle}
                zones={zoneConfig}
            />
        </div>;
    };

    const renderClusterViewFrame = () => {
        if (params != null && params.enableFrame) {
            // if (true) {
            if (activity && activity.liveFrame) {
                // Check if activity object exists and has liveFrame property
                return (
                    <div style={{ margin: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {entryLabel()}
                        {renderFrame(rows, columns, activity.liveFrame)}
                        {exitLable()}
                    </div>
                );
            } else {
                return <></>
            }
        } else {
            return <></>
        }
    };

    const handleConfirmButtonClickedForPowerCycle = () => {
        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer === 0) {
                    clearInterval(countdown);
                    setZoneSetupConfirmBtn(false);
                    navigate(`/selectFLOfloor?floor=${floorId}&name=${floorName}`, {
                        state: { ...location.state, "final data": floConfigData },
                    });
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);

    }

    function frameOff() {
        if (params != null && params.enableFrame != null && agentId) {
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            let raw = JSON.stringify({
                "frame_debug": false
            });
            let url = `https://agent.electricimp.com/${agentId}/frame_debug`;
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(url, requestOptions)
                .then(response => response.text())
                .catch(error => console.log('error', error));
        }
    }

    return (
        <div className="container-fluid g-0">
            <div className="row justify-content-center g-0 mainDiv">
                <div className="grid col-sm-12 col-lg-8" >
                    <div className="row g-0">
                        {loading && <Loader />}
                        {
                            <div className="col-12" style={{ background: "#F5F5F5", padding: '0 5px' }}>
                                <div className="row g-0">
                                    <div className="col-11 mx-auto" style={{ background: "#F5F5F5" }}>
                                        <HeaderSecondary primaryText={room} secondaryText={title} goBack={!reset ? () => { resetAndDone(); } : () => { frameOff(); navigate(-1) }} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <InfoBox text={info} />
                    <br />
                    {showModal ?
                        <div className="col-10 col-sm-6 col-lg-6 mx-auto ">
                            <Mod1
                                text={{ primaryText: modalDetails.primaryText, secondaryText: modalDetails.secondaryText, icon: infoIcon }}
                                closeModal={() => setShowModal(false)}
                                confirmButton={{ text: modalDetails.confirmButtonText, onClick: () => { CalibartionConfrimationByModal(); setShowModal(false) } }}
                            />
                        </div>
                        : null
                    }
                    {EditClick ?
                        <div className="col-10 col-sm-6 col-lg-6 mx-auto ">
                            <Mod2
                                text={{ primaryText: "Please Stand below the door frame", icon: infoIcon }}
                                closeModal={() => setEditClick(false)}
                                confirmButton={{ text: "Yes", onClick: () => { setReset(false); setEditClick(false) } }}
                            />
                        </div>
                        : null


                    }
                    {zoneSetupClick ?
                        <div className="col-10 col-sm-6 col-lg-6 mx-auto ">
                            <Mod1
                                text={{ primaryText: "Device will do self calibration now", secondaryText: "Stand away from FOV", icon: infoIcon }}
                                closeModal={() => setZoneSetupClick(false)}
                                confirmButton={{
                                    text: modalDetails.confirmButtonText,
                                    onClick: () => {
                                        // setReset(false);
                                        setZoneSetupClick(false);
                                        setZoneSetupConfirmBtn(true);
                                        setupDone();
                                        coldReboot(agentId);
                                        handleConfirmButtonClickedForPowerCycle();

                                    }
                                }}
                            />
                        </div>
                        : null
                    }
                    {zoneSetupConfirmBtn ?
                        <div className="col-10 col-sm-6 col-lg-6 mx-auto ">
                            <Mod1
                                text={{
                                    primaryText: "Device is configuring with new configurations",
                                    secondaryText: `Please wait for ${timer} seconds to move to testing zone`,
                                    icon: infoIcon
                                }}
                            />
                        </div>

                        : null
                    }
                    <div className={`col-1${reset ? 2 : 1} mx-auto`}>
                        {renderClusterViewFrame()}
                    </div>
                    <div className={`col-1${reset ? 2 : 1} mx-auto`}>
                        {
                            reset ?
                                <BottomGridEditFLOZoneSetup
                                    title={title}
                                    room={room}
                                    buttonTextEdit={"Edit Zone"}
                                    onClick={handleEditClick}
                                    buttonTextCalibration={"Calibrate frame"}
                                    onCalibrate={handleCalibrateClick}
                                    buttonTextDone={"Zone setup done"}
                                    setupDone={() => { setZoneSetupClick(true) }}
                                />
                                :
                                <BottomGrid2FLOZoneSetup
                                    title={title}
                                    room={room}
                                    showLeftRight={true}
                                    leftShiftZone={zoenShiftLeft}
                                    rightShiftZone={zoneShiftRight}
                                    changeEdit={() => {
                                        setReset(!reset);
                                        saveUpdatedZone(zoneConfig);
                                    }}
                                    callback={resetCallback}
                                />
                        }
                    </div>
                    <br />
                </div>
            </div>
        </div>
    );
}

export default FLOSetUp;